/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import analytics from '@analytics';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import useSWR from 'swr';
// import { getCookieConsent } from '@leaf/helpers';
import {
  CurrentCompanyProfileUserQuery,
  CustomDomain,
  Registries,
  useCurrentCompanyProfileUserQuery,
  useCustomDomainQuery,
  useListCompanyRolesQuery,
  useRefinitivTokenQuery,
  useRegistryImportStatusQuery,
} from '@/apollo/generated';
import ErrorComponent from '@/components/utils/error-component';
import Redirect from '@/components/utils/redirect';
import { FLAGS } from '@/hooks/use-feature-toggles';
import { CompanyRole, CompanyRoles } from '@/hooks/use-permission';
import { getTranslator } from '@/i18n';
import { formatError } from '@/utils/error-helpers';
import fetcher, { fetchOptions } from '@/utils/fetcher';
import { getFieldValue, RefinitivQuoteData } from '@/utils/refinitiv';
import routes from '@/utils/routes';

interface CurrentCompanyProfileUserContext {
  companyRoles: CompanyRole[];
  companyRolesLoading: boolean;
  currentCompanyProfileUser: NonNullable<
    CurrentCompanyProfileUserQuery['currentCompanyProfileUser']
  >;
  customDomain: CustomDomain | null;
  customDomainLoading: boolean;
  fullName: string;
  hasCompanyShareholderOfferPermission: boolean;
  hub: string;
  isComputershare: boolean;
  isDemo: boolean;
  isPremium: boolean;
  isQmodEnabled: boolean;
  isTrial: boolean;
  isUK: boolean;
  // True if one of the secondary tickers is in TSXV market
  latestRegisterReportDate: string;
  linkedinDaysUntilExpiryString: string | null;
  liveInvestorHubBaseUrl: string;
  liveShareholderOfferId?: string | null;
  price: number | undefined;
  priceChange: number | undefined;
  profile: NonNullable<
    CurrentCompanyProfileUserQuery['currentCompanyProfileUser']
  >['profile'];
  setSidebarIsCollapsed: React.Dispatch<
    React.SetStateAction<SidebarIsCollaped>
  >;
  sidebarIsCollapsed: SidebarIsCollaped;
  totalShareholderOffers: number;
  translate: (
    key: string,
    params?:
      | {
          [key: string]: string | number;
        }
      | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
}

interface Props {
  children?: React.ReactNode;
  currentCompanyProfileUser: NonNullable<
    CurrentCompanyProfileUserQuery['currentCompanyProfileUser']
  >;
}

const CurrentCompanyProfileUserContext =
  createContext<CurrentCompanyProfileUserContext | null>(null);

function resolveMarketListingKey(ticker: {
  listingKey: string;
  marketKey: string;
}): string {
  const { listingKey, marketKey } = ticker;
  const lowerCaseMarketKey = marketKey.toLowerCase();

  switch (lowerCaseMarketKey) {
    case 'aqse':
      return `${listingKey}-aq`;
    case 'lse':
      return `${listingKey}-l`;
    default:
      return listingKey;
  }
}

type SidebarIsCollaped = boolean | undefined;

const CurrentCompanyProfileUserProvider: React.ComponentType<Props> = ({
  children,
  currentCompanyProfileUser,
}) => {
  // useCurrentCompanyProfileUpdatedSubscription();
  const { data, loading: customDomainLoading } = useCustomDomainQuery();
  const { data: registryData } = useRegistryImportStatusQuery();

  const { data: companyRolesData, loading: companyRolesLoading } =
    useListCompanyRolesQuery();

  const [sidebarIsCollapsed, setSidebarIsCollapsed] =
    useState<SidebarIsCollaped>(undefined);

  useEffect(() => {
    const storedValue = localStorage.getItem('sidebarIsCollapsed');
    if (storedValue !== null) {
      try {
        const parsed = JSON.parse(storedValue);
        setSidebarIsCollapsed(typeof parsed === 'boolean' ? parsed : false);
      } catch {
        setSidebarIsCollapsed(false);
      }
    } else {
      setSidebarIsCollapsed(false);
    }
  }, []);

  useEffect(() => {
    if (typeof sidebarIsCollapsed !== 'undefined') {
      localStorage.setItem(
        'sidebarIsCollapsed',
        JSON.stringify(sidebarIsCollapsed)
      );
    }
  }, [sidebarIsCollapsed]);

  const companyRoles: CompanyRole[] = useMemo(() => {
    return companyRolesData?.listCompanyRoles
      ? companyRolesData.listCompanyRoles.map((companyRole) => {
          const companyRoleName = companyRole.name as keyof typeof CompanyRoles;

          return {
            ...CompanyRoles[companyRoleName],
            id: companyRole.id,
            key: companyRoleName,
          };
        })
      : [];
  }, [companyRolesData?.listCompanyRoles]);

  const latestRegisterReportDate = useMemo(() => {
    if (registryData?.registryImportStatus?.latestReportDate) {
      return dayjs(registryData.registryImportStatus.latestReportDate).format(
        'DD/MM/YYYY'
      );
    }
    return '-';
  }, [registryData?.registryImportStatus?.latestReportDate]);

  const fullName = useMemo(() => {
    if (
      currentCompanyProfileUser.user.firstName &&
      currentCompanyProfileUser.user.lastName
    ) {
      return `${currentCompanyProfileUser.user.firstName} ${currentCompanyProfileUser.user.lastName}`;
    }

    return 'Anonymous';
  }, [currentCompanyProfileUser]);

  const customDomain = data?.customDomain ? data?.customDomain : null;

  const liveInvestorHubBaseUrl = useMemo(() => {
    const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';

    if (
      data?.customDomain?.customDomain &&
      data.customDomain.isVercelDomainVerified
    ) {
      return `${protocol}://${data.customDomain.customDomain}`;
    }
    const updatedMarketListingKey = resolveMarketListingKey(
      currentCompanyProfileUser.profile.ticker
    );

    return `${protocol}://${updatedMarketListingKey}.${process.env.NEXT_PUBLIC_HERMES_DOMAIN}`;
  }, [currentCompanyProfileUser, data]);

  let locale = null;

  const isUK = ['aqse', 'lse'].includes(
    currentCompanyProfileUser.profile.ticker.marketKey
  );

  if (isUK) locale = 'en-UK';
  if (currentCompanyProfileUser.profile.ticker.marketKey === 'asx')
    locale = 'en-AU';

  const translate = getTranslator(locale);

  // used false instead of null because going !linkedinDaysUntilExpiry returns true if linkedinDaysUntilExpiry == 0
  const linkedinDaysUntilExpiry =
    currentCompanyProfileUser.profile.socialConnection
      ?.linkedinSetupCompleted &&
    dayjs(
      currentCompanyProfileUser.profile.socialConnection
        ?.linkedinRefreshTokenExpiresAt
    ).diff(dayjs().startOf('day'), 'days') < 9
      ? dayjs(
          currentCompanyProfileUser.profile.socialConnection
            ?.linkedinRefreshTokenExpiresAt
        ).diff(dayjs().startOf('day'), 'days')
      : false;

  const linkedinDaysUntilExpiryString = useMemo(() => {
    if (linkedinDaysUntilExpiry !== false && linkedinDaysUntilExpiry < 0) {
      return 'expired';
    }

    if (linkedinDaysUntilExpiry !== false) {
      switch (linkedinDaysUntilExpiry) {
        case 0:
          return 'expiring today';
        case 1:
          return 'expiring in 1 day';
        default:
          return `expiring in ${linkedinDaysUntilExpiry} days`;
      }
    }
    return null;
  }, [linkedinDaysUntilExpiry]);

  /* Get price from Refinitiv ------------------------------ START ------------------------------ */
  // Move here to include `refinitivIdentificationCode` when fetching the quote
  const { data: refinitivTokenData, refetch: refinitivTokenRefetch } =
    useRefinitivTokenQuery();

  const [price, setPrice] = useState<number>();
  const [priceChange, setPriceChange] = useState<number>();

  const params = {
    listingKey: currentCompanyProfileUser.profile.ticker.listingKey,
    marketKey: currentCompanyProfileUser.profile.ticker.marketKey,
    refinitivIdentificationCode:
      currentCompanyProfileUser.profile.ticker.refinitivIdentificationCode ||
      '',
    token: refinitivTokenData?.token?.value || '',
  };

  const queryString = new URLSearchParams(params);

  const { data: quoteData, error: quoteError } = useSWR<
    RefinitivQuoteData,
    Error
  >(
    !!currentCompanyProfileUser.profile.ticker.marketKey &&
      !!currentCompanyProfileUser.profile.ticker.listingKey &&
      !currentCompanyProfileUser.profile.isUnlisted &&
      refinitivTokenData?.token?.value
      ? `/api/refinitiv/quote-lists/get-simple-data?${queryString}`
      : null,
    fetcher,
    fetchOptions
  );

  useEffect(() => {
    let ignore = false;

    if (quoteData && !ignore) {
      const cfLast = getFieldValue(quoteData, 'CF_LAST');
      const pctchng = getFieldValue(quoteData, 'PCTCHNG');

      if (typeof cfLast === 'number') {
        setPrice(cfLast);
      }

      if (typeof pctchng === 'number') {
        setPriceChange(pctchng);
      } else {
        setPriceChange(0);
      }
    }

    return () => {
      ignore = true;
    };
  }, [quoteData]);

  useEffect(() => {
    if (quoteError && quoteError.message.includes('Token expired')) {
      // Token expired, get a new token
      refinitivTokenRefetch();
    }
  }, [quoteError, refinitivTokenRefetch]);
  /* Get price from Refinitiv ------------------------------ END ------------------------------ */

  return (
    <CurrentCompanyProfileUserContext.Provider
      value={{
        companyRoles,
        companyRolesLoading,
        currentCompanyProfileUser,
        customDomain,
        customDomainLoading,
        fullName,
        hasCompanyShareholderOfferPermission:
          currentCompanyProfileUser.profile
            .hasCompanyShareholderOfferPermission,
        hub: currentCompanyProfileUser.profile.hub,
        isComputershare:
          currentCompanyProfileUser.profile.registry ===
          Registries.Computershare,
        isDemo: currentCompanyProfileUser.profile.isDemo,
        isPremium: currentCompanyProfileUser.profile.isPremium,
        isQmodEnabled:
          currentCompanyProfileUser.profile.secondaryTickers.filter(
            (st) => st.marketKey === 'tsxv'
          ).length > 0,
        isTrial: currentCompanyProfileUser.profile.isTrial,
        isUK,
        latestRegisterReportDate,
        linkedinDaysUntilExpiryString,
        liveInvestorHubBaseUrl,
        liveShareholderOfferId:
          currentCompanyProfileUser.profile.liveShareholderOfferId,
        price,
        priceChange,
        profile: currentCompanyProfileUser.profile,
        setSidebarIsCollapsed,
        sidebarIsCollapsed,
        totalShareholderOffers:
          currentCompanyProfileUser.profile.totalShareholderOffers,
        translate,
      }}
    >
      {children}
    </CurrentCompanyProfileUserContext.Provider>
  );
};

export function useCurrentCompanyProfileUser() {
  const context = useContext(CurrentCompanyProfileUserContext);

  if (!context) {
    throw new Error(
      'useCurrentCompanyProfileUser must be used within a CurrentCompanyProfileUserProvider'
    );
  }

  return context;
}

type Options = {
  customLoader?: React.ReactNode;
  permission?: string;
};

export function withCurrentCompanyProfileUserContext(
  Component: React.ComponentType<any>,
  options: Options = {}
) {
  const WithCurrentCompanyProfileUserContext: React.ComponentType<any> = (
    props
  ) => {
    const { data, error, loading } = useCurrentCompanyProfileUserQuery({
      fetchPolicy: 'cache-and-network',
    });

    const { asPath } = useRouter();

    useEffect(() => {
      // const cookieConsent = getCookieConsent();
      // const analyticsAllowed = cookieConsent && cookieConsent.analytics;

      const isHubs2Published =
        !!data?.currentCompanyProfileUser?.profile?.liveHubBuilderWebsite &&
        data?.currentCompanyProfileUser?.profile?.featuresEnabled.includes(
          FLAGS.websitePageBuilder
        );

      // Send user details to Segment.
      if (data?.currentCompanyProfileUser?.user) {
        analytics.identify(data.currentCompanyProfileUser.user.id, {
          account_id: data.currentCompanyProfileUser.profile.id,
          company_name: data.currentCompanyProfileUser.profile.name,
          companyTicker: `${data.currentCompanyProfileUser.profile.ticker.marketKey}:${data.currentCompanyProfileUser.profile.ticker.listingKey}`,
          email: data.currentCompanyProfileUser.user.email,
          isDemo: data.currentCompanyProfileUser.profile.isDemo,
          isHubs2Published,
          isPremium: data.currentCompanyProfileUser.profile.isPremium,
          isTrial: data.currentCompanyProfileUser.profile.isTrial,
          name: [
            data.currentCompanyProfileUser.user.firstName,
            data.currentCompanyProfileUser.user.lastName,
          ]
            .join(' ')
            .trim(),
          phone: data.currentCompanyProfileUser.user.mobileNumber ?? '',
          simulatingAdminUserId: '',
          title: data.currentCompanyProfileUser.jobTitle ?? '',
        });
      }
    }, [data]);

    const ifIsTempFixForPAMCampaign = asPath.includes(
      '/pam/engagement/interactive-media/updates/1404'
    );

    const ifIsTempFixForCryositeCampaign = asPath.includes(
      '/cte/engagement/campaigns/%7B%7B%20link%20%7D%7D'
    );

    if (data?.currentCompanyProfileUser) {
      const hasPermission = data.currentCompanyProfileUser.permissions.some(
        (companyProfileUserPermission) =>
          companyProfileUserPermission.name === options.permission
      );

      if (!!options.permission && !hasPermission) {
        return (
          <Redirect
            force
            href={routes.insufficientPermission.href(
              data.currentCompanyProfileUser.profile.ticker.marketListingKey
            )}
          />
        );
      }

      return (
        <CurrentCompanyProfileUserProvider
          currentCompanyProfileUser={data.currentCompanyProfileUser}
        >
          <Component {...props} />
        </CurrentCompanyProfileUserProvider>
      );
    }

    if (error) {
      // When suspicious cloud ip is detected
      if (formatError(error) === 'Access denied!') {
        return <ErrorComponent />;
      }
      return <ErrorComponent statusCode={500} />;
    }

    if (loading) {
      if (options.customLoader) {
        return <>{options.customLoader}</>;
      }

      return <div className="h-screen w-screen bg-gray-50" />;
    }

    if (ifIsTempFixForPAMCampaign) {
      return (
        <Redirect
          force
          href="https://investorhub.panasiametals.com/activity-updates/corporate-activities-update-7-march-2024"
        />
      );
    }

    // This is a temp fix for the cryosite campaign should be removed by 2025-03-01
    if (ifIsTempFixForCryositeCampaign) {
      return (
        <Redirect force href={'https://investorhub.cryosite.com/auth/signup'} />
      );
    }

    return (
      <Redirect
        force
        href={routes.auth.login.href}
        redirectUrl={window.location.pathname}
      />
    );
  };

  return WithCurrentCompanyProfileUserContext;
}
